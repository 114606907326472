import { exportFileAxios } from "../api/http"
import { Message, MessageBox } from "element-ui"
import AraleQRCode from "arale-qrcode"
import CryptoJS from "crypto-js"
import CopyBox from "@/components/CopyBox.vue"

/**
 * 防抖函数 - 在事件被触发 delay 时间后执行一次函数调用，如果 delay 时间内事件被多次触发，则重新计时
 * @param {Function} callback - 回调函数
 * @param {Number} delay - 延迟时间
 */
export function debounce (callback, delay = 0) {
  let timer = null

  return (...args) => {
    clearTimeout(timer)

    timer = setTimeout(() => {
      callback.apply(this, args)
    }, delay)
  }
}

// 获取数字格式的月份
export function toNumber (number) {
  if (number < 10) {
    return `0${number}`
  }
  return number
}

// 手机号验证
export const phoneReg = /^1[2-9]\d{9}$/
export const validatePhoneNumber = (rule, value, callback) => {
  if (!value) {
    callback(new Error("请输入手机号"))
  } else {
    const reg = phoneReg
    if (!reg.test(value)) {
      callback(new Error("手机号格式有误"))
    } else {
      callback()
    }
  }
}

// 密码验证，要求：
// 1). 8-16位
// 2). 英文大、小写字母、数字和特殊字符中至少 3 种类型的字符
export const passwordReg = /^(?:(?=.*[a-z])(?=.*\d)(?=.*[@!%*#_?&])|(?=.*[a-z])(?=.*\d)(?=.*[A-Z])|(?=.*\d)(?=.*[A-Z])(?=.*[@!%*#_?&])|(?=.*[a-z])(?=.*[A-Z])(?=.*[@!%*#_?&]))[A-Za-z\d@!%*#_?&]{8,16}$/
export const validatePassword = (rule, value, callback) => {
  if (!value) {
    callback(new Error("请输入密码"))
  } else {
    const reg = passwordReg
    if (!reg.test(value)) {
      callback(new Error("包含英文大小写、数字和特殊字符的至少3种类型(8-16位)"))
    } else {
      callback()
    }
  }
}
export const validatePasswordWithoutWarning = (rule, value, callback) => {
  if (!value) {
    callback(new Error("请输入密码"))
  } else {
    const reg = passwordReg
    if (!reg.test(value)) {
      callback(new Error(" "))
    } else {
      callback()
    }
  }
}
// 判断值是否为空
export const validateEmpty = (message) => {
  return (rule, value, callback) => {
    if (!value) {
      callback(new Error("请输入四位数字验证码"))
    } else {
      callback()
    }
  }
}

export const userNameReg = /(?=.*[A-Za-z])^[A-Za-z0-9][a-zA-Z0-9_-]{3,19}$/
export const validateUserName = (rule, value, callback) => {
  if (!value) {
    callback(new Error("请输入用户名"))
  } else {
    if (userNameReg.test(value)) {
      callback()
    } else {
      callback(new Error("支持字母、数字、- _；包含字母且不以- _开头(4-20位)"))
    }
  }
}

// 账户密码错误
export const validateUserNameOrPhone = (rule, value, callback) => {
  if (!value) {
    callback(new Error("请输入用户名/手机号"))
  } else {
    if (userNameReg.test(value) || phoneReg.test(value)) {
      callback()
    } else {
      callback(new Error("账户格式错误"))
    }
  }
}

export const generateRules = () => {
  return {
    userName: [
      { message: "用户名不能为空", trigger: ["change", "blur"], required: true },
      { validator: validateUserName, trigger: "blur" }
    ],
    phone: [
      { validator: validatePhoneNumber, trigger: "blur" },
      { message: "手机号不能为空", trigger: ["change", "blur"], required: true }
    ],
    smsCode: [
      { validator: validateEmpty("请输入手机验证码"), trigger: "blur" },
      { len: 4, message: "长度不正确", trigger: ["blur"] },
      { message: "手机验证码不能为空", trigger: ["change", "blur"], required: true }
    ],
    captcha: [
      { validator: validateEmpty("请输入图形验证码"), trigger: "blur" },
      { len: 4, message: "长度不正确", trigger: ["blur"] },
      { message: "图形验证码不能为空", trigger: ["change", "blur"], required: true }
    ],
    // 旧密码
    oldPassword: [
      { validator: validatePassword, trigger: ["blur", "change"] },
      { message: "旧密码不能为空", trigger: ["change", "blur"], required: true }
    ],
    // 新密码
    newPassword: [
      { validator: validatePasswordWithoutWarning, trigger: ["blur", "change"] },
      { message: "新密码不能为空", trigger: ["change", "blur"], required: true }
    ],
    // 登录密码
    password: [
      { validator: validatePassword, trigger: ["blur", "change"] }
    ],
    // 初始秘密
    initialPassword: [
      { validator: validatePasswordWithoutWarning, trigger: ["blur", "change"] },
      { message: "初始密码不能为空", trigger: ["change", "blur"], required: true }
    ]
  }
}

export function exportFiles (url, name, params) {
  exportFileAxios(url, params).then(res => {
    var date = new Date()

    date.getYear()
    date.getFullYear()
    date.getMonth()
    date.getDate()

    let nowMonth = date.getMonth() + 1
    let strDate = date.getDate()
    const seperator = "-"

    if (nowMonth >= 1 && nowMonth <= 9) {
      nowMonth = "0" + nowMonth
    }

    if (strDate >= 0 && strDate <= 9) {
      strDate = "0" + strDate
    }

    const nowDate = seperator + date.getFullYear() + seperator + nowMonth + seperator + strDate

    const fileName = name + nowDate + ".xlsx"
    const url = window.URL.createObjectURL(res)
    const aLink = document.createElement("a")
    aLink.style.display = "none"
    aLink.href = url
    aLink.setAttribute("download", fileName)
    document.body.appendChild(aLink)
    aLink.click()
    document.body.removeChild(aLink) // 下载完成移除元素
    window.URL.revokeObjectURL(url) // 释放掉blob对象
  })
    .catch(err => {
      Message.error(err.message)
      console.log(err)
    })
}

// 下载文件名不需要时间
export function exportFilesNoTime (url, name, params) {
  exportFileAxios(url, params).then(res => {
    const fileName = name + ".xlsx"
    const url = window.URL.createObjectURL(res)
    const aLink = document.createElement("a")
    aLink.style.display = "none"
    aLink.href = url
    aLink.setAttribute("download", fileName)
    document.body.appendChild(aLink)
    aLink.click()
    document.body.removeChild(aLink) // 下载完成移除元素
    window.URL.revokeObjectURL(url) // 释放掉blob对象
  })
    .catch(err => {
      Message.warning(err.message)
      console.log(err)
    })
}

export function creatQrCode (QRcode) {
  const res = JSON.stringify(QRcode).replace("\"", "").replace("\"", "")
  const a = new AraleQRCode({
    render: "svg" /*  生成的类型 'svg' or 'table dom元素类型 */,
    text: res /* 二维码的链接*/,
    size: 256
  })
  const result = new XMLSerializer().serializeToString(a)
  const src =
    "data:image/svg+xml;base64," +
    window.btoa(unescape(encodeURIComponent(result)))

  return src
}

export function unsecuredCopyToClipboard (text) {
  const textArea = document.createElement("textarea")
  textArea.value = text
  document.body.appendChild(textArea)
  textArea.focus()
  textArea.select()
  try {
    document.execCommand("copy")
  } catch (err) {
    console.error("Unable to copy to clipboard", err)
  }
  document.body.removeChild(textArea)
}

// 生成AES密钥
export function generateKey (keySource) {
  const str = keySource.slice(0, 16).padEnd(16, "0")
  return CryptoJS.enc.Utf8.parse(str)
}

// AES加密
export function encrypt (source, keySource) {
  const key = generateKey(keySource) // 16位
  const encrypted = CryptoJS.AES.encrypt(source, key, {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7
  })
  return encrypted.ciphertext.toString()
}

// 解密AES密码
export function decrypt (source, keySource) {
  console.log("source", source)
  const key = generateKey(keySource) // 16位
  const hex = CryptoJS.enc.Hex.parse(source) // 转成16进制
  console.log("hex", hex)
  const base64 = CryptoJS.enc.Base64.stringify(hex) // base64
  console.log("base64", base64)
  const bytes = CryptoJS.AES.decrypt(base64, key, {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7
  })
  console.log("encrypted", bytes)
  const aa = bytes.toString(CryptoJS.enc.Utf8)
  console.log("result", aa)
  return CryptoJS.enc.Utf8.stringify(bytes)
}

// 复制用户名密码
export function copyPassword (h, userName, password) {
  const passwordText = decrypt(password, userName)
  MessageBox({
    title: "账号密码",
    message: h(CopyBox, {
      props: {
        list: [{
          label: "用户名:",
          value: userName
        }, {
          label: "密码:",
          value: passwordText
        }]
      }
    },
    [
      h("div", { class: "content" }, "请及时复制账号密码，关闭弹窗后密码不可见。")
    ]
    ),
    type: "warning",
    customClass: "message-box__credentials",
    showCancelButton: true,
    showConfirmButton: false,
    cancelButtonText: "关闭"
  }).catch(() => { })
}

export const basicParams = {
  client_id: "client_prepay_web",
  client_secret: "123456",
  scope: "app",
  serviceType: SERVICE_TYPE
}
// 生成登录参数
export function generateParams (params) {
  return {
    ...basicParams,
    ...params
  }
}

// 登录
export const SERVICE_SUB_TYPE_LOGIN = `${SERVICE_TYPE}-login`
// 忘记密码
export const SERVICE_SUB_TYPE_FORGET_PASSWORD = `${SERVICE_TYPE}-forget-password`
// 修改手机号码
export const SERVICE_SUB_TYPE_BIND = `${SERVICE_TYPE}-bind`
// 个人中心修改密码
export const SERVICE_SUB_TYPE_CHANGE_PASSWORD = `${SERVICE_TYPE}-change-password`
export const transList = {
  timeFrameList1: "尖",
  timeFrameList2: "峰",
  timeFrameList3: "平",
  timeFrameList4: "谷"
}
